import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1514db14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-title mb-1" }
const _hoisted_2 = { class: "card-text card-text-courses" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 4,
  class: "btn btn-bottom w-100 font-weight-bold card-actions",
  disabled: true
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createBlock(_component_content_card, {
    class: "text-center card-outer",
    "border-color-hex": _ctx.themeColorHex
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: "d-flex flex-column align-items-center banner-logo-container",
        style: _normalizeStyle({
                    cursor: _ctx.courseIsOwned ? 'pointer' : ''
                }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToCourse())),
        onKeydown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateToCourse()))
      }, [
        _createElementVNode("div", {
          class: "image-text-overlay text-middle-center course-card-image",
          style: _normalizeStyle(_ctx.headerImageCss)
        }, null, 4),
        _createElementVNode("div", {
          class: "logo-circle",
          style: _normalizeStyle(_ctx.logoImageCss)
        }, null, 4)
      ], 36)
    ]),
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.course.name) + " ", 1),
        (!_ctx.course.isOwned)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: "lock",
              size: "sm"
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.course.summary), 1)
    ]),
    footer: _withCtx(() => [
      (_ctx.isLibrary)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.libraryUrl,
            target: "_blank",
            class: "btn btn-bottom w-100 font-weight-bold card-actions"
          }, " Visit The eSpired Library ", 8, _hoisted_3))
        : (_ctx.canPurchase)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: _ctx.course.purchaseUrl ?? '',
              target: "_blank",
              class: "btn btn-bottom w-100 font-weight-bold card-actions"
            }, " Purchase " + _toDisplayString(_ctx.course.name), 9, _hoisted_4))
          : (_ctx.canRequest)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "btn btn-bottom w-100 font-weight-bold card-actions",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.requestCourseClicked && _ctx.requestCourseClicked(...args)))
              }, [
                (_ctx.courseRequestLoading)
                  ? (_openBlock(), _createBlock(_component_loading_indicator, { key: 0 }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, "Ask Parent For " + _toDisplayString(_ctx.course.name), 1))
              ]))
            : (_ctx.course.isOwned)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 3,
                  to: {
                    name: _ctx.routeNames.course,
                    params: {
                        courseId: _ctx.course.id
                    }
                },
                  class: "btn btn-bottom w-100 font-weight-bold card-actions"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.courseNameInButton
                        ? `Explore ${_ctx.course.name}`
                        : "Start Learning"), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              : (!_ctx.canRequest)
                ? (_openBlock(), _createElementBlock("button", _hoisted_6, " Requested "))
                : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["border-color-hex"]))
}