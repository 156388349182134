
import { CourseItemModel } from "@/models";
import { computed, defineComponent, PropType, ref } from "vue";
import swal from "sweetalert";
import { AppActionTypes, useStore } from "@/store";
import { routeNames } from "@/router/routeNames";
import { COLOR_DEFAULT } from "@/helpers";
import { useRouter } from "vue-router";

const __default__ = defineComponent({
    props: {
        course: {
            type: Object as PropType<CourseItemModel>,
            required: true
        },
        courseNameInButton: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const store = useStore();
        const courseRequestLoading = ref(false);
        const courseRequested = ref(false);
        const router = useRouter();

        const headerImageCss = `background-image: url(${props.course.headerImageUrl});`;
        const logoImageCss = `background-image: url(${props.course.logoImageUrl});`;

        const requestCourseClicked = async (): Promise<void> => {
            courseRequestLoading.value = true;
            await store.dispatch(AppActionTypes.requestCourse, {
                courseId: props.course.id
            });

            courseRequestLoading.value = false;
            courseRequested.value = true;

            await swal({
                text: "We just emailed your parent!",
                icon: "success"
            });
        };

        const themeColorHex = props.course.courseColorHex ?? COLOR_DEFAULT;

        const navigateToCourse = async (): Promise<void> => {
            // make sure the course id is > 0 because the eSpired library has id of -1
            if (props.course.isOwned && (props.course?.id ?? 0) > 0) {
                await router.push({
                    name: routeNames.course,
                    params: {
                        courseId: props.course.id
                    }
                });
            }
        };

        const canPurchase = computed(
            () =>
                !props.course.isOwned &&
                props.course.canPurchase &&
                props.course.purchaseUrl
        );

        const canRequest = computed(
            () =>
                !props.course.isOwned &&
                props.course.canRequest &&
                !courseRequested.value
        );

        return {
            headerImageCss,
            logoImageCss,
            requestCourseClicked,
            isLibrary: computed(() => props.course.id === -1),
            libraryUrl: computed(() => store.state.user?.libraryUrl ?? ""),
            canPurchase,
            canRequest,
            courseRequestLoading,
            routeNames,
            themeColorHex,
            navigateToCourse,
            courseIsOwned: computed(
                () => props.course.isOwned && props.course.id
            )
        };
    }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "16c5a4fa": (_ctx.themeColorHex)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__